import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ItemTable, ItemTableColumn } from './ItemTable';
import { Toast, shallowEquals, uidSerial } from './utils';
import * as api from './api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, createStyles, Theme, TextField, Chip } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField as FormikMuiTextField } from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';

const tagColumns: ItemTableColumn<api.Tag>[] = [
  { key: "id", name: "ID" },
  { key: "uid", name: "UID" },
  { key: "serial", name: "Serial" },
  { key: "info", name: "Info" },
  { key: "permissions", name: "Permissions" },
  { key: "created", name: "Created" }
];

interface Groups {
  all: api.Group[];
  byId: {[id: number]: api.Group};
}

export default function TagPage() {

  const [groups, setGroups] = useState<Groups>({ all: [], byId: {}});
  const [tags, setTags] = useState<api.Tag[]>([]);
  const [query, setQuery] = useState("");
  const [openItem, setOpenItem] = useState<api.Tag|null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  async function loadGroups() {
    try {
      const groups: Groups = { all: [], byId: {}};
      groups.all = await api.getGroups();
      groups.all.sort((a,b) => a.name.localeCompare(b.name));
      for (const g of groups.all) if (g.id) groups.byId[g.id] = g;
      setGroups(groups);
    } catch (e:any) {
      Toast.error("Failed to load groups", e);
    }
  }

  const loadTags = useCallback(async () => {
    try {
      const result = await api.queryTags(query, page * rowsPerPage, rowsPerPage);
      setTotalCount(result.count);
      setTags(result.items);
    } catch (e:any) {
      Toast.error("Failed to load tags", e);
    }
  }, [query, page, rowsPerPage]);

  function renderCell(item: api.Tag, key: keyof api.Tag) {
    switch(key) {
      case "created":
        return new Date(item.created*1000).toLocaleDateString();
      case "permissions":
        const names = item.permissions
          .map(p => groups.byId[p.groupId]?.name)
          .filter(n=>!!n);
        let text = names.slice(0,2).join(", ");
        if (names.length > 2) text += ", ...";
        return text;
      default:
        return item[key];
    }
  }

  const handleCancel = () => setOpenItem(null);
  const handleSubmit = async (data: api.Tag) => {
    try {
      await api.saveTag(data);
      if (data.id) {
        setOpenItem(null);
        loadTags();
        return true;
      } else {
        Toast.success("New tag saved successfully!");
      }
      loadTags();
    } catch (e:any) {
      Toast.error("Failed to save a tag", e);
    }
    return false;
  };
  const handleAdd = () => {
    setOpenItem({uid: "", info: "", created: 0, permissions: []});
  };
  const handleEdit = (item: api.Tag) => {
    setOpenItem(item);
  };
  const handleSearch = (query: string) => {
    setPage(0);
    setQuery(query);
  };
  const handleDelete = async (items: api.Tag[]) => {
    try {
      const ids = items.filter(i => i.id !== undefined).map(i => i.id);
      await api.deleteTags(ids as number[]);
      await loadTags();
    } catch (e:any) {
      Toast.error("Failed to delete tags", e);
    }
  };
  const handleExport = () => {
    const url = api.exportTagsUrl(query);
    console.log("opening", url);
    window.open(url);
  };
  const paginator = {
    page, rowsPerPage, totalCount, setRowsPerPage, setPage };
  useEffect(() => { loadGroups() }, []);
  useEffect(() => { loadTags() }, [loadTags]);

  return (
    <>
      <ItemTable<api.Tag>
        title="Tags"
        columns={tagColumns}
        data={tags}
        render={renderCell}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onSearch={handleSearch}
        onDelete={handleDelete}
        onExport={handleExport}
        paginator={paginator} />
      {openItem &&
      <TagDialog
        item={openItem}
        groups={groups}
        onSubmit={handleSubmit}
        onCancel={handleCancel} />}
    </>
  );
}


const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1)
      },
    }
  }));

interface FormData {
  id?: number;
  uid: string;
  info: string;
  groups: api.Group[];
};

function TagDialog({item, groups, onSubmit, onCancel}:
  { item: api.Tag,
    groups: Groups,
    onSubmit: (values: api.Tag) => Promise<boolean>,
    onCancel: () => void })
{
  const classes = useFormStyles();
  const uidRef = useRef<HTMLInputElement>(null);
  const initialValues: FormData = {
    uid: item.uid,
    info: item.info,
    groups: item.permissions.map(p=>groups.byId[p.groupId]).filter(g=>!!g)
  };
  const validate = (values: FormData) => !values.uid ? { uid: "This field is required" } : {};
  const handleSubmit = async (values: FormData, helpers: any) => {
    const tag: api.Tag = {
      id: item?.id,
      uid: values.uid,
      info: values.info,
      created: item.created ? item.created : new Date().getTime() / 1000,
      permissions: values.groups.map(g => ({ groupId: g.id as number, level: 1 }))
    };
    if (!(await onSubmit(tag))) {
      helpers.setSubmitting(false);
      const uidInput = uidRef.current;
      if (uidInput) {
        uidInput.focus();
        uidInput.setSelectionRange(0, uidInput.value.length);
      }
    }
  }
  return (
    <Dialog open={!!item} onClose={onCancel}>
      <Formik<FormData>
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}>
          {({ values, isSubmitting, isValid, setValues, handleBlur }) => (
            <Form>
              <DialogTitle>{ item.id ? `Edit tag ${values.uid} (${uidSerial(values.uid)})` : "Add a new tag" }</DialogTitle>
              <DialogContent>
                <div className={classes.root}>
                  <Field fullWidth autoFocus inputRef={uidRef} component={FormikMuiTextField} name="uid" label="UID" />
                  <Field fullWidth component={FormikMuiTextField} name="info" label="Info" />
                  <Autocomplete<api.Group,true>
                    fullWidth
                    multiple
                    value={values.groups}
                    onBlur={handleBlur}
                    onChange={(e:unknown, selected:api.Group[]) =>
                      setValues({ ...values, groups: selected })}
                    options={groups.all}
                    getOptionLabel={group => group.name}
                    getOptionSelected={(a,b) => a.id === b.id}
                    renderTags={(tags, props) =>
                      tags.map((tag, index) =>
                        <Chip label={tag.name} {...props({index})}/>)}
                    renderInput={(params:any) =>
                      <TextField {...params} label="Permissions" />}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button disabled={!isValid || isSubmitting || shallowEquals(initialValues, values)} type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
      </Formik>
    </Dialog>
  );
}