import React, { useState, useEffect } from 'react';
import { ItemTable, ItemTableColumn } from './ItemTable';
import { Toast, shallowEquals } from './utils';
import * as api from './api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, createStyles, Theme, Chip, TextField } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField as FormikMuiTextField } from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';

interface GroupItem extends api.Group {
  members: api.Group[];
}

const groupColumns: ItemTableColumn<GroupItem>[] = [
  { key: "id", name: "ID" },
  { key: "name", name: "Name" },
  { key: "info", name: "Info" },
  { key: "members", name: "Subgroups" }
];

interface Groups {
  all: api.Group[];
  byId: {[id: number]: api.Group};
  items: GroupItem[];
}

function renderCell(item: GroupItem, key: keyof GroupItem) {
  if (key === "members") {
      const names = item.members.map(m => m.name)
      let text = names.slice(0,2).join(", ");
      if (names.length > 2) text += ", ...";
      return text;
  }
  return item[key];
};

export default function GroupPage() {

  const [groups, setGroups] = useState<Groups>({ all: [], byId: {}, items: []});
  const [query, setQuery] = useState<string>("");
  const [openItem, setOpenItem] = useState<GroupItem|null>(null);

  async function loadGroups() {
    try {
      const groups: Groups = { all: [], byId: {}, items: []};
      groups.all = await api.getGroups();
      for (const g of groups.all) if (g.id) groups.byId[g.id] = g;
      groups.items = groups.all.map( g => ({
        ...g, members: g.memberIds.map(id => groups.byId[id]!)
      }));
      setGroups(groups);
    } catch (e:any) {
      Toast.error("Failed to load groups", e);
    }
  }

  const handleCancel = () => setOpenItem(null);
  const handleSubmit = async (data: api.Group) => {
    try {
      await api.saveGroup(data);
      await loadGroups();
      setOpenItem(null);
      return true;
    } catch (e:any) {
      Toast.error("Failed to save a group", e);
      return false;
    }
  };
  const handleAdd = () => {
    setOpenItem({name: "", info: "", memberIds: [], members: []});
  };
  const handleEdit = (item: GroupItem) => {
    setOpenItem(item);
  };
  const handleSearch = (query: string) => {
    setQuery(query);
  };
  const handleDelete = async (items: GroupItem[]) => {
    try {
      const ids = items.filter(i => i.id !== undefined).map(i => i.id);
      await api.deleteGroups(ids as number[]);
      await loadGroups();
    } catch (e:any) {
      Toast.error("Failed to delete groups", e);
    }
  };
  useEffect(() => { loadGroups(); }, []);
  
  let data = groups.items;
  if (query.length >= 2) {
    let matches: (s: string) => boolean;
    if (query.toLowerCase() === query) {
      matches = (s: string) => s.toLowerCase().indexOf(query) !== -1;
    } else {
      matches = (s: string) => s.indexOf(query) !== -1;
    }
    data = groups.items.filter(g => matches(g.name) || matches(g.info));
  }

  return (
    <>
      <ItemTable<GroupItem>
        title="Groups"
        columns={groupColumns} 
        data={data}
        render={renderCell}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onSearch={handleSearch}
        onDelete={handleDelete} />
      {openItem &&
      <GroupDialog 
        item={openItem}
        groups={groups} 
        onSubmit={handleSubmit}
        onCancel={handleCancel} />}
    </>
  );
}

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1)
      },
    }
  }));

interface FormData {
  id?: number;
  name: string;
  info: string;
  members: api.Group[];
};

function GroupDialog({item, groups, onSubmit, onCancel}: 
  { item: GroupItem, 
    groups: Groups, 
    onSubmit: (group: api.Group) => Promise<boolean>,
    onCancel: () => void }) {
  const classes = useFormStyles();
  const initialValues = {
    name: item.name, 
    info: item.info, 
    members: item.members
  };
  const validate = (values: any) => !values.name ? { name: "This field is required" } : {};
  const handleSubmit = async (values: FormData, helpers: any) => {
    const group: api.Group = {
      id: item?.id,
      name: values.name,
      info: values.info,
      memberIds: values.members.map(m => m.id) as number[]
    };
    if (!(await onSubmit(group)))
      helpers.setSubmitting(false);
  }
  return (
    <Dialog open={!!item} onClose={onCancel}>
      <Formik<FormData>
        initialValues={initialValues} 
        validate={validate} 
        onSubmit={handleSubmit}>
          {({ values, isSubmitting, isValid, setValues, handleBlur }) => (
            <Form>
              <DialogTitle>{ item.id ? ("Edit group " + item.name) : "Add new group" }</DialogTitle>
              <DialogContent>
                <div className={classes.root}>
                  <Field fullWidth autoFocus component={FormikMuiTextField} name="name" label="Name" />
                  <Field fullWidth component={FormikMuiTextField} name="info" label="Info" />
                  <Autocomplete
                    fullWidth
                    multiple
                    value={values.members}
                    onBlur={handleBlur}
                    onChange={(e:unknown, selected:api.Group[]) => 
                      setValues({ ...values, members: selected })}
                    options={groups.all}
                    getOptionLabel={group => group.name}
                    getOptionSelected={(a,b) => a.id === b.id}
                    renderTags={(tags, props) =>
                      tags.map((tag, index) => 
                        <Chip label={tag.name} {...props({index})}/>)}
                    renderInput={(params:any) =>
                      <TextField {...params} label="Subgroups" />}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button disabled={!isValid || isSubmitting || shallowEquals(initialValues, values)} type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
      </Formik>
    </Dialog>
  );
}