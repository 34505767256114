import React, { useState } from "react";
import * as api from './api';
import { Avatar, Button, CircularProgress, Paper, TextField, Theme, createStyles, makeStyles } from "@material-ui/core";
import splash from './login.webp';
import marimatic from './marimatic.png';
import { LockOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      zbackground: "#282c34",
      background: theme.palette.grey[200],
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    window: {
      backgroundImage: `url(${splash})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "-60px",
      backgroundPositionY: "-60px",
      width: "800px",
      height: "500px",
      display: "flex",
      justifyContent: "right"
    },
    box: {
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: "320px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    title: {
      color: theme.palette.primary.dark,
      xcolor: "white",
      fontWeight: "bold",
      fontSize: "28px",
      textAlign: "center",
      borderTopRightRadius: theme.shape.borderRadius,
      xbackground: theme.palette.primary.dark,
      padding: theme.spacing(1)
    },
    form: { padding: theme.spacing(2) },
    button: { marginTop: theme.spacing(1) },
    lockIcon: { background: theme.palette.primary.dark },
    marimatic: {
      marginTop: theme.spacing(2),
      width: "50%"
    },
    error: {
      color: "red",
      textAlign: "left"
    }
  }));

export default function LoginPage({onLoggedIn}:{
  onLoggedIn: (user: api.User) => void
}) {  
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();
  const login = async (e:any) => {
    e.preventDefault();
    try {
      onLoggedIn(await api.login(name, password));
    } catch (ex:any) {
      console.log(ex);
      setError("Login failed");
      setTimeout(() => setError(""), 5000);
    }
  }
  return (
    <div className={classes.background}>
      <Paper className={classes.window} elevation={5}>
        <div className={classes.box}>
          { busy ? 
          <CircularProgress/> : 
          <Avatar className={classes.lockIcon}><LockOutlined/></Avatar>
          }
          <div className={classes.title}>MetroTaifun ACS</div>
          <form className={classes.form} onSubmit={login}>
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              label="User"
              name="username"
              autoComplete="username"
              onChange={e => setName(e.target.value)}
              autoFocus />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password" />
            <div className={classes.error}>{error || <span>&nbsp;</span>}</div>
            <Button 
              type="submit" 
              color="primary" 
              fullWidth 
              variant="contained"
              disabled={name === "" || password === "" || busy }
              className={classes.button}>Login</Button>
          </form>
          <img src={marimatic} alt="MariMatic" className={classes.marimatic}/>
        </div>
      </Paper>
    </div>
  );
}
