import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { createStyles, makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Container, Icon, Typography, Snackbar, Tooltip, Theme, LinearProgress } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { Alert } from '@material-ui/lab';
import * as api from './api';
import { events, Toast } from './utils';
import GroupPage from './GroupPage';
import DoorPage from './DoorPage';
import TagPage from './TagPage';
import LogPage from './LogPage';
import LoginPage from './LoginPage';
import UserPage from './UserPage';
import logo from './logo.png';

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: red,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    branding: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      marginRight: "1.5rem",
      textShadow: "1px 2px black"
    },
    navlink: {
      width: "6rem",
      margin: theme.spacing(1),
    },
    activelink: {
      width: "6rem",
      margin: theme.spacing(1),
      position: "relative",
      top: "1px",
      left: "1px",
      boxShadow: "none",
      border: "1px inset",
      borderColor: theme.palette.primary.light,
      background: theme.palette.primary.dark
    },
    container: {
      padding: theme.spacing(2)
    }
  }),
);

const NavLink = ({to, children} : {to:string, children:any}) => {
  const classes = useStyles();
  const history = useHistory();
  const selected = useLocation().pathname.startsWith(to);
  const handleClick = useCallback(() => history.push(to), [history, to]);
  return (<Button
    className={selected ? classes.activelink : classes.navlink}
    onClick={handleClick}
    variant="contained"
    disableElevation={selected}
    color="primary">{children}</Button>);
};

function Root() {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [toast, setToast] = useState<Toast|null>(null);
  const [user, setUser] = useState<api.User|undefined>();
  events.join("toast", setToast);
  events.join("busy", setBusy);
  useEffect(() => () => {
    events.leave("toast", setToast);
    events.leave("busy", setBusy);
  }, []);
  const logout = useCallback(async () => {
    await api.logout();
    setUser(undefined);
  }, []);
  if (user === undefined) {
    return <LoginPage onLoggedIn={setUser}/>;
  }
  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar>
          <img src={logo} alt="" style={{position: "relative", left: "-0.85rem"}}/>
          <Typography className={classes.branding}>MetroTaifun
          <span style={{fontSize: "1rem", position: "relative", top: "-1rem"}}>&reg;</span>
            ACS
          </Typography>
          <NavLink to="/groups">Groups</NavLink>
          <NavLink to="/doors">Doors</NavLink>
          <NavLink to="/tags">Tags</NavLink>
          <NavLink to="/users">Users</NavLink>
          <NavLink to="/log">Log</NavLink>
          <div className={classes.grow} />
          {user.name}
          <Tooltip title="Logout">
            <Button color="inherit" onClick={logout}>
              <Icon>exit_to_app</Icon>
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {busy && <LinearProgress/>}
      <Container maxWidth="md" className={classes.container}>
        <Switch>
          <Route path="/groups">
            <GroupPage/>
          </Route>
          <Route path="/doors">
             <DoorPage/>
          </Route>
          <Route path="/tags">
            <TagPage/>
          </Route>
          <Route path="/users">
            <UserPage currentUser={user}/>
          </Route>
          <Route path="/log">
            <LogPage/>
          </Route>
          <Route path="*">
            <Redirect to="/tags"/>
          </Route>
        </Switch>
      </Container>
      <Snackbar 
        open={!!toast}
        onClose={()=>setToast(null)}
        autoHideDuration={toast?.duration}>
        <Alert severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Router>
      <Root/>
    </Router>
  </MuiThemeProvider>
);

export default App;
