import React, { useState, useEffect } from 'react';
import { ItemTable, ItemTableColumn } from './ItemTable';
import { Toast, shallowEquals } from './utils';
import * as api from './api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, createStyles, Theme, TextField } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField as FormikMuiTextField } from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';

interface DoorItem extends api.Door {
  group: api.Group;
}

const doorColumns: ItemTableColumn<DoorItem>[] = [
  { key: "id", name: "ID" },
  { key: "address", name: "Address" },
  { key: "info", name: "Info" },
  { key: "group", name: "Group" }
];

function renderCell(item: DoorItem, key: keyof DoorItem) {
  return key === "group" ? item.group?.name ?? "" : item[key];
}

interface Groups {
  all: api.Group[];
  byId: {[id: number]: api.Group};
}

export default function DoorPage() {

  const [groups, setGroups] = useState<Groups>({ all: [], byId: {}});
  const [doors, setDoors] = useState<DoorItem[]>([]);
  const [query, setQuery] = useState<string>("");
  const [openItem, setOpenItem] = useState<DoorItem|null>(null);

  const loadDoors = async () => {
    try {
      const [doors, groupData] = await Promise.all([
        api.getDoors(),
        api.getGroups()
      ]);
      const groups: Groups = {all: [], byId: {}};
      for (const g of groupData) {
        if (g.id) {
          groups.byId[g.id] = g;
          groups.all.push(g);
        }
      }
      groups.all.sort((a,b) => a.name.localeCompare(b.name));
      setGroups(groups);
      setDoors(doors.map(d => ({ ...d, group: groups.byId[d.groupId]! })));
    } catch (e:any) {
      Toast.error("Failed to load doors", e);
    }
  }
  const handleCancel = () => setOpenItem(null);
  const handleSubmit = async (data: api.Door) => {
    try {
      await api.saveDoor(data);
      await loadDoors();
      if (data.id) {
        setOpenItem(null);
        return true;
      } else {
        Toast.success("New door saved successfully!");
      }
    } catch (e:any) {
      Toast.error("Failed to save a door", e);
    }
    return false;    
  };
  const handleOpen = async (id: number) => {
    try {
      await api.openDoor(id);
    } catch (e:any) {
      Toast.error("Failed to open a door", e);
    }
  };
  const handleAdd = () => {
    if (groups.all.length > 0) {
      const group = groups.all[0];
      setOpenItem({address: "", info: "", groupId: group.id!, group });
    } else {
      Toast.warning("First create a group for the door");
    }
  };
  const handleEdit = (item: DoorItem) => {
    setOpenItem(item);
  };
  const handleSearch = (query: string) => {
    setQuery(query);
  };
  const handleDelete = async (items: DoorItem[]) => {
    try {
      await api.deleteDoors(items.map(i=>i.id!));
      await loadDoors();
    } catch (e:any) {
      Toast.error("Failed to delete doors", e);
    }
  };
  useEffect(() => { loadDoors(); }, []);
  
  let data = doors;
  if (query.length >= 2) {
    let matches: (s: string) => boolean;
    if (query.toLowerCase() === query) {
      matches = (s: string) => s.toLowerCase().indexOf(query) !== -1;
    } else {
      matches = (s: string) => s.indexOf(query) !== -1;
    }
    data = doors.filter(d => matches(d.address) || matches(d.info) || matches(d.group.name));
  }

  return (
    <>
      <ItemTable<DoorItem>
        title="Doors"
        columns={doorColumns} 
        data={data}
        render={renderCell}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onSearch={handleSearch}
        onDelete={handleDelete} />
      {openItem &&
      <DoorDialog 
        item={openItem}
        groups={groups} 
        onOpen={handleOpen}
        onSubmit={handleSubmit}
        onCancel={handleCancel} />}
    </>
  );
}


const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1)
      },
    }
  }));

interface FormData {
  id?: number,
  address: string;
  info: string;
  group: api.Group;
};

function DoorDialog({item, groups, onSubmit, onCancel, onOpen}: 
  { item: DoorItem,
    groups: Groups,
    onSubmit: (values: api.Door) => Promise<boolean>,
    onCancel: () => void,
    onOpen: (id: number) => unknown })
{
  const classes = useFormStyles();
  const initialValues: FormData = { 
    address: item.address,
    info: item.info,
    group: item.group
  };
  const validate = (values: FormData) => !values.address ? { address: "This field is required" } : {};
  const handleSubmit = async (values: FormData, helpers: any) => {
    const door: api.Door = { 
      id: item?.id,      
      address: values.address, 
      info: values.info,
      groupId: values.group.id! };
    if (!(await onSubmit(door)))
      helpers.setSubmitting(false);
  }
  return (
    <Dialog open={!!item} onClose={onCancel}>
      <Formik<FormData>
        initialValues={initialValues} 
        validate={validate} 
        onSubmit={handleSubmit}>
          {({ values, isSubmitting, isValid, setValues, handleBlur }) => (
            <Form>
              <DialogTitle>{ item.id ? ("Edit door " + values.address) : "Add a new door" }</DialogTitle>
              <DialogContent>
                <div className={classes.root}>
                  <Field fullWidth autoFocus component={FormikMuiTextField} name="address" label="Address" />
                  <Field fullWidth component={FormikMuiTextField} name="info" label="Info" />
                  <Autocomplete
                    fullWidth
                    value={values.group}
                    onBlur={handleBlur}
                    onChange={(e:unknown, value:api.Group|null) => 
                      value && setValues({ ...values, group: value! })}
                    options={groups.all}
                    getOptionLabel={group => group.name}
                    renderInput={(params:any) =>
                      <TextField {...params} label="Group" />}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>onOpen(item.id!)} color="primary" disabled={!item.id}>
                  Open
                </Button>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button disabled={!isValid || isSubmitting || shallowEquals(initialValues, values)} type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
      </Formik>
    </Dialog>
  );
}