import { events } from "./utils";

const baseUrl = window.location.protocol + "//" + window.location.host + "/api/";

export async function request(method: string, path: string, body?: any): Promise<any> {
  events.submit("busy", true);
  try {
    const url = baseUrl + path;
    const options: any = {
      method,
      credentials: "include",
      headers: {}
    };
    if (body) {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }
    const res = await fetch(url, options);
    if (!res.ok)
      throw Error(`request failed: (${res.status}) ${res.statusText}`);
    if (/application\/json/.test(res.headers.get("Content-Type") ?? ""))
      return await res.json();
    if (/text\/plain/.test(res.headers.get("Content-Type") ?? ""))
      return await res.text();
    const data = await res.blob();
    return data.size > 0 ? data : null;
  } finally {
    events.submit("busy", false);
  }
}

function encodeUrlParams(params: any) {
  const items = [] as string[];
  for (const key in params) {
    const name = encodeURIComponent(key);
    const value = encodeURIComponent(params[key]);
    items.push(`${name}=${value}`);
  }
  return items.join("&");
}

export function get(path: string, params?: any) {
  if (params) {
    const items = [] as string[];
    for (const key in params) {
      const name = encodeURIComponent(key);
      const value = encodeURIComponent(params[key]);
      items.push(`${name}=${value}`);
    }
    path += "?" + encodeUrlParams(params);
  }
  return request("get", path);
}

export function post(path: string, data: any) {
  return request("post", path, data);
}

export function save(path: string, item: any) {
  if (item.id) {
    return post(`${path}/${item.id}`, item);
  } else {
    return post(path, item);
  }
}

export async function del(path: string, ids: number[]) {
  const qs = ids.map(id => `id=${id}`).join("&");
  await request("delete", `${path}?${qs}`);
}

export interface QueryResult<T> {
  count: number;
  items: T[];
}

export interface Group {
  id?: number;
  name: string;
  info: string;
  memberIds: number[];
}

export function getGroups()  {
  return get("group") as Promise<Group[]>;
}

export function saveGroup(group: Group) {
  return save("group", group);
}

export function deleteGroups(ids: number[]) {
  return del("group", ids);
}

export interface Door {
  id?: number;
  address: string;
  info: string;
  groupId: number;
}

export function getDoors() {
  return get("door") as Promise<Door[]>;
}

export function saveDoor(door: Door) {
  return save("door", door);
}

export function deleteDoors(ids: number[]) {
  return del("door", ids);
}

export function openDoor(id: number) {
  return post(`door/${id}/open`, null);
}

export interface Permission {
  groupId: number;
  level: number;
}

export interface Tag {
  id?: number;
  uid: string;
  serial?: string;
  info: string;
  created: number;
  permissions: Permission[];
}

export function queryTags(
  filter: string, 
  first: number = 0, 
  count: number = 10)
{
  return get("tag", {filter, first, count}) as Promise<QueryResult<Tag>>;
}

export function saveTag(tag: Tag) { 
  return save("tag", tag);
}

export function deleteTags(ids: number[]) {
  return del("tag", ids);
}

export function exportTagsUrl(filter: string) {
  return baseUrl + "tag/export?filter=" + encodeURIComponent(filter);
}

export interface LogEntry {
  id: number;
  time: number;
  tagUid: string;
  tagSerial: string;
  doorInfo: string;
  result: number;
}

export type LogFilter = Partial<LogEntry>;

export function queryLog(filter: LogFilter, first: number, count: number)
{
  const qs: any = { first, count };
  if (filter.tagUid) qs.tag = filter.tagUid;
  if (filter.doorInfo) qs.door = filter.doorInfo;
  if (filter.time) qs.time = filter.time;
  return get("report", qs) as Promise<QueryResult<LogEntry>>
}

export function exportLogUrl(filter: LogFilter) {
  const qs: any = {};
  if (filter.tagUid) qs.tag = filter.tagUid;
  if (filter.doorInfo) qs.door = filter.doorInfo;
  if (filter.time) qs.time = filter.time;
  return baseUrl + "report/export?" + encodeUrlParams(qs);
}

export interface User {
  id?: number;
  name: string;
  password?: string;
  info: string;
  admin: boolean;
}

export function login(name: string, password: string)
{
  return post("login", { name, password }) as Promise<User>;
}

export function logout()
{
  return post("logout", {});
}

export function getUsers() {
  return get("user") as Promise<User[]>;
}

export function saveUser(user: User) {
  return save("user", user);
}

export function deleteUsers(ids: number[]) {
  return del("user", ids);
}
