import React, { useState, useEffect } from 'react';
import { ItemTable, ItemTableColumn } from './ItemTable';
import { Toast, shallowEquals } from './utils';
import * as api from './api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, createStyles, Theme, TextField, FormControlLabel } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { Checkbox, TextField as FormikMuiTextField } from 'formik-material-ui';
import { CheckOutlined } from '@material-ui/icons';

const userColumns: ItemTableColumn<api.User>[] = [
  { key: "id", name: "ID" },
  { key: "name", name: "Name" },
  { key: "info", name: "Info" },
  { key: "admin", name: "Admin" }
];

function renderCell(item: api.User, key: keyof api.User) {
  return key !== "admin" ? item[key] : item[key] ? <CheckOutlined/> : "";
}

export default function UserPage({currentUser} : {currentUser: api.User}) {

  const [users, setUsers] = useState<api.User[]>([]);
  const [query, setQuery] = useState<string>("");
  const [openItem, setOpenItem] = useState<api.User|undefined>();

  const loadUsers = async () => {
    try {
      setUsers(await api.getUsers());
    } catch (e:any) {
      Toast.error("Failed to load users", e);
    }
  }
  const handleCancel = () => setOpenItem(undefined);
  const handleSubmit = async (data: api.User) => {
    try {
      await api.saveUser(data);
      await loadUsers();
      setOpenItem(undefined);
      return true;
    } catch (e:any) {
      Toast.error("Failed to save a user", e);
      return false;
    }
  };
  const handleAdd = !currentUser.admin ? undefined : () => {
    setOpenItem({ name: "", info: "", admin: false });
  };
  const handleEdit = (item: api.User) => {
    setOpenItem(item);
  };
  const handleSearch = (query: string) => {
    setQuery(query);
  };
  const handleDelete = !currentUser.admin ? undefined : 
  async (items: api.User[]) => {
    try {
      await api.deleteUsers(items.map(i=>i.id!));
      await loadUsers();
    } catch (e:any) {
      Toast.error("Failed to delete users", e);
    }
  };
  useEffect(() => { loadUsers(); }, []);
  
  let data = users;
  if (query.length >= 2) {
    let matches: (s: string) => boolean;
    if (query.toLowerCase() === query) {
      matches = (s: string) => s.toLowerCase().indexOf(query) !== -1;
    } else {
      matches = (s: string) => s.indexOf(query) !== -1;
    }
    data = users.filter(d => matches(d.name) || matches(d.info));
  }

  return (
    <>
      <ItemTable<api.User>
        title="Users"
        columns={userColumns} 
        data={data}
        render={renderCell}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onSearch={handleSearch}
        onDelete={handleDelete} />
      {openItem &&
      <UserDialog
        item={openItem}
        currentUser={currentUser}
        onSubmit={handleSubmit}
        onCancel={handleCancel} />}
    </>
  );
}


const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1)
      },
    }
  }));

interface FormData {
  id?: number,
  name: string;
  password: string;
  confirm: string;
  info: string;
  admin: boolean;
};

function UserDialog({item, currentUser, onSubmit, onCancel }: 
  { item: api.User,
    currentUser: api.User,
    onSubmit: (values: api.User) => Promise<boolean>,
    onCancel: () => void })
{
  const readonly = !currentUser.admin && currentUser.id !== item.id;
  const classes = useFormStyles();
  const initialValues: FormData = { 
    name: item.name,
    info: item.info,
    admin: item.admin,
    password: "",
    confirm: ""
  };
  const validate = (values: FormData) => {
    const errors: any = {};
    if (!values.name) errors.name = "This field is required";
    if (!item.id && !values.password) errors.password = "This field is required";
    if (values.password && values.password !== values.confirm) errors.confirm = "Passwords do not match";
    return errors;
  }
  const handleSubmit = async (values: FormData, helpers: any) => {
    const user: api.User = { 
      id: item?.id,
      name: values.name,
      password: values.password || undefined,
      info: values.info,
      admin: values.admin };
    if (!(await onSubmit(user)))
      helpers.setSubmitting(false);
  }
  const title = item.id === undefined ? 
    "Add a new user" : readonly ? 
      "User " + item.name : "Edit user " + item.name;
  return (
    <Dialog open={!!item} onClose={onCancel}>
      <Formik<FormData>
        initialValues={initialValues} 
        validate={validate} 
        onSubmit={handleSubmit}>
          {({ values, isSubmitting, isValid, setValues, handleBlur }) => (
            <Form>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <div className={classes.root}>
                  <Field disabled={readonly} fullWidth autoFocus component={FormikMuiTextField} name="name" label="Name" />
                  <Field disabled={readonly} fullWidth component={FormikMuiTextField} name="info" label="Info" />
                  <FormControlLabel label="Administrator" control={
                    <Field disabled={!currentUser.admin} type="checkbox" component={Checkbox} name="admin"/>
                  }/>
                  <Field type="password" disabled={readonly} fullWidth component={FormikMuiTextField} name="password" label="Password" placeholder="Unchanged"/>
                  <Field type="password" disabled={readonly} fullWidth component={FormikMuiTextField} name="confirm" label="Confirm password" placeholder="Unchanged"/>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                { !readonly && 
                <Button disabled={!isValid || isSubmitting || shallowEquals(initialValues, values)} type="submit" color="primary">
                  Save
                </Button>}
              </DialogActions>
            </Form>
          )}
      </Formik>
    </Dialog>
  );
}