import { Color } from "@material-ui/lab/Alert";

export const  _ = (s: any) => s;

class EventBus {

  channels: { [channel:string]: CallableFunction[] } = {};

  join(channel: string, listener: CallableFunction) {
    if (channel in this.channels) this.channels[channel].push(listener);
    else this.channels[channel] = [listener];
  }
  leave(channel: string, listener: CallableFunction) {
    const ch = this.channels[channel];
    if (ch) this.channels[channel] = ch.filter(x => x !== listener);
  }
  submit(channel: string, ...args: any[]) {
    const ch = this.channels[channel];
    if (ch) for (const f of ch) f(...args);
  }
}

export const events = new EventBus();

export class Toast {

  message: string;
  severity: Color;
  duration: number;

  constructor(message="", severity: Color="info", duration=5000) {
    this.message = message;
    this.severity = severity;
    this.duration = duration;
  }
  submit() {
    events.submit("toast", this);
  }
  static error(message: string, error: Error) {
    console.log(message, error);
    new Toast(message, "error", 10000).submit();
  }
  static success(message: string) {
    new Toast(message, "success", 2000).submit();
  }
  static warning(message: string) {
    new Toast(message, "warning", 5000).submit();
  }
}

export function shallowEquals(o1: any, o2: any) {
  for (const key in o1)
    if (o1[key] !== o2[key])
      return false;
  return true;
}

export function uidSerial(hexUid: string) {
  const uid = parseInt(hexUid.substring(hexUid.length - 8), 16);
  if (isNaN(uid)) return "";
  const serial = uid.toString();
  return serial.substring(serial.length-5);
}