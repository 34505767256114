import React, { ReactNode, useState } from "react";
import { 
  makeStyles, createStyles, Paper, TableContainer, 
  Table, TableHead, TableRow, TableCell, Checkbox, TableBody, 
  TablePagination, Toolbar, Tooltip, Typography, IconButton, Icon, 
  TextField, InputAdornment, Theme 
} from "@material-ui/core";

export interface Item {
  id?: string | number;
}

export interface ItemTableColumn<T extends Item> {
  key: keyof T;
  name: string;
}

export interface ItemTablePaginator {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  setPage: (page: number) => void;
  setRowsPerPage: (count: number) => void;
}

export interface ItemTableProps<T extends Item> {
  title: string;
  columns: ItemTableColumn<T>[];
  data: T[];
  render: (item: T, key: keyof T) => ReactNode;
  onAdd?: () => void;
  onEdit: (item: T) => void;
  onSearch: (query: string) => void;
  onDelete?: (items: T[]) => void;
  onExport?: () => void;
  paginator?: ItemTablePaginator;
}

export function ItemTable<T extends Item>(props: ItemTableProps<T>) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<T[]>([]);

  let rows: T[], totalCount: number, emptyRows: number;
  let handleChangePage: (_:unknown, page:number) => void;
  let handleChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement>) => void;

  if (props.paginator) {
    const paginator = props.paginator;
    rows = props.data;
    totalCount = paginator.totalCount;
    emptyRows = paginator.rowsPerPage - rows.length;
    handleChangePage = (_: unknown, page: number) => paginator.setPage(page);
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      paginator.setRowsPerPage(parseInt(event.target.value, 10));
      paginator.setPage(0);
    }
  } else {
    rows = props.data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    totalCount = props.data.length;
    emptyRows = rowsPerPage - Math.min(rowsPerPage, totalCount - page * rowsPerPage); 
    handleChangePage = (_: unknown, page: number) => setPage(page);
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);      
    }
  }
  const handleToggleAll = (evt: any) => {
    setSelected(evt.target.checked ? rows : []);
  }
  const handleToggle = (evt: any, item: T) => {
    evt.stopPropagation();
    if (evt.target.checked) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter(i => i !== item));
    }
    return true;
  }
  const handleDelete = !props.onDelete ? undefined : () => {
    setSelected([]);
    props.onDelete?.(selected);
  }

  return (
    <Paper elevation={5} style={{background: "#eee"}}>
      <ItemTableToolbar 
        numSelected={selected.length} 
        title={props.title}
        onSearch={throttle(props.onSearch)}
        onDelete={handleDelete}
        onAdd={props.onAdd}
        onExport={props.onExport}/>
      <TableContainer style={{background: "white"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < rows.length}
                  checked={selected.length > 0 && selected.length === rows.length}
                  onChange={handleToggleAll} />
              </TableCell>
              {props.columns.map(c =>
                <TableCell component="th" key={c.key.toString()}>{c.name}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const isSelected = selected.includes(row);
              return (
                <TableRow hover key={row.id} selected={isSelected} onClick={()=>props.onEdit(row)}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected} onClick={(evt: any)=>handleToggle(evt, row)}/>
                  </TableCell>
                  {props.columns.map(c =>
                    <TableCell key={c.key.toString()} component="th">{props.render(row, c.key)}</TableCell>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={props.columns.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={props.paginator?.rowsPerPage ?? rowsPerPage}
        page={props.paginator?.page ?? page}
        labelRowsPerPage="Rows per page:"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
    </Paper>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flex: '1 1 50%',
    },
    button: {
      marginLeft: theme.spacing(1)
    }
  }),
);

interface ItemTableToolbarProps {
  numSelected: number;
  title: string;
  onSearch: (query:string) => void;
  onDelete?: () => void;
  onAdd?: () => void;
  onExport?: () => void;
}

function ItemTableToolbar(props: ItemTableToolbarProps) {
  const classes = useToolbarStyles();
  const [query, setQuery] = useState("");
  const handleQueryChange = (query: string) => {
    setQuery(query);
    props.onSearch(query);
  };

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {props.title}
      </Typography>
    {props.onDelete !== undefined && props.numSelected > 0 ? (
      <>
        <Typography color="inherit" variant="subtitle1" component="div">
          Delete {props.numSelected}
        </Typography>
        <Tooltip title="Delete">
          <IconButton className={classes.button} onClick={props.onDelete}>
            <Icon>delete</Icon>
          </IconButton>
        </Tooltip>        
      </>
    ) : (
      <>
        <ItemSearchField value={query} onChange={handleQueryChange}/>
        { props.onAdd ? (
          <Tooltip title="Add">
            <IconButton className={classes.button} onClick={props.onAdd}>
              <Icon>add_circle_outline</Icon>
            </IconButton>
          </Tooltip>
        ): null }
        { props.onExport ? (
          <Tooltip title="Export">
            <IconButton className={classes.button} onClick={props.onExport}>
              <Icon>save</Icon>
            </IconButton>
          </Tooltip>
        ) : null }
      </>
    )}    
  </Toolbar>
  );
};

interface ItemSearchFieldProps {
  value: string;
  onChange: (value: string) => void;
}

function ItemSearchField(props: ItemSearchFieldProps) {
  const handleChange = (evt: any) => props.onChange(evt.target.value);
  return (
    <TextField placeholder="Search" variant="outlined" size="small"
      style={{background: "white"}}
      value={props.value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
    }}/>
  );
}

function throttle(f: CallableFunction, delay: number = 200) {
  let timeout:any = null;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      f(...args);
     }, delay);
  }
}