import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles, createStyles, Paper, TableContainer,
  Table, TableHead, TableRow, TableCell, TableBody,
  TablePagination, Toolbar, Tooltip, Typography,
  TextField, IconButton, Icon, Theme
} from "@material-ui/core";
import { Toast } from "./utils";
import * as api from './api';

function resultName(code: number) {
  switch(code) {
    case -2: return "Unknown Door";
    case -1: return "Unknown Tag";
    case 0: return "Denied";
    case 1: return "Granted";
  }
  return "Unknown";  
}

export default function LogPage() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [entries, setEntries] = useState([] as api.LogEntry[]);
  const [filter, setFilter] = useState({} as api.LogFilter);

  const load = useCallback(async () => {
    try {
      const result = await api.queryLog(filter, page*rowsPerPage, rowsPerPage);
      setTotalCount(result.count);
      setEntries(result.items);
    } catch (e:any) {
      Toast.error("Failed to load log entries ", e);
    }
  }, [page, rowsPerPage, filter]);

  const handleExport = useCallback(() => {
    const url = api.exportLogUrl(filter);
    console.log("opening", url);
    window.open(url);
  }, [filter]);

  useEffect(() => { load() }, [load]);

  return (
    <Paper elevation={5} style={{ background: "#eee" }}>
      <LogToolbar filter={filter} onFilter={setFilter} onExport={handleExport} />
      <TableContainer style={{ background: "white" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th">Time</TableCell>
              <TableCell component="th">Tag</TableCell>
              <TableCell component="th">Door</TableCell>
              <TableCell component="th">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(e =>
              <TableRow key={e.id}>
                <TableCell>{new Date(e.time*1000).toLocaleString()}</TableCell>
                <TableCell>{e.tagUid} ({e.tagSerial})</TableCell>
                <TableCell>{e.doorInfo}</TableCell>
                <TableCell>{resultName(e.result)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Rows per page:"
        onPageChange={(_: any, page: number) => setPage(page)}
        onRowsPerPageChange={(e: any) => setRowsPerPage(parseInt(e.target.value, 10))}
      />
    </Paper>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  filter: {
    flexGrow: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiTextField-root": {
      marginRight: theme.spacing(1),
      "& input": {
        background: "white",
        width: "6rem",
      },
      " & [type=datetime-local]": {
        width: "12rem"
      }
    }
  }
}));

interface LogToolbarProps {
  filter: api.LogFilter;
  onFilter: (filter: api.LogFilter) => void;
  onExport: () => void;
}

function LogToolbar({ filter, onFilter, onExport }: LogToolbarProps) {

  const classes = useToolbarStyles();
  const [tag, setTag] = useState(filter.tagUid ?? "");
  const [door, setDoor] = useState(filter.doorInfo ?? "");
  const [time, setTime] = useState(filter.time ? new Date(filter.time*1000).toLocaleString() : "");

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (evt: any) => setter(evt.target.value);
  const handleApply = () => {
    const filter: api.LogFilter = {}
    const t = new Date(time).getTime();
    if (!isNaN(t)) filter.time = t / 1000;
    if (tag.length > 0) filter.tagUid = tag;
    if (door.length > 0) filter.doorInfo = door;
    onFilter(filter);
  }

  return (
    <Toolbar>
      <Typography variant="h6" component="div">Log</Typography>
      <div className={classes.filter}>
        <Tooltip title="Filter by tag">
          <TextField placeholder="Tag" variant="outlined" size="small"
            value={tag} onChange={handleChange(setTag)} />
        </Tooltip>
        <Tooltip title="Filter by door">
          <TextField placeholder="Door" variant="outlined" size="small"
            value={door} onChange={handleChange(setDoor)} />
        </Tooltip>
        <Tooltip title="Filter by time">
          <TextField variant="outlined" size="small" type="datetime-local"
            value={time} onChange={handleChange(setTime)} />
        </Tooltip>
        <Tooltip title="Apply filter">
          <IconButton onClick={handleApply}><Icon>search</Icon></IconButton>
        </Tooltip>
        <Tooltip title="Export">
          <IconButton onClick={onExport}><Icon>save</Icon></IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
}